import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import SEO from "../../components/seo";
import TrailTitle from "../../components/TrailTitle";

const delay = keyframes`
  0% {
    opacity: 0;
    height : 0;
  }

  100% {
    opacity: 0;
    height : initial;
  }
`;

const fade = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const DelayFade = styled(Col)`
  animation: ${delay} ${(props) => props.order * 0.1}s,
    ${fade} 0.1 ${(props) => props.order * 0.1}s;
`;

const BgRow = styled(Row)`
  background-image: url("${(props) => props.background}");
  background-repeat: no-repeat;
  background-position: top right;
`;

const QualityByTrimesterPage = ({ location, data, pageContext, ...props }) => {
  // console.log(data);

  return (
    <>
      <SEO title='Indikator Mutu Layanan' />
      <Img fluid={data.cover.childImageSharp.fluid} />
      <BgRow className='pt-5' background={data.bg.childImageSharp.fixed.src}>
        <Col
          xs={"12"}
          md={"4"}
          className='d-flex flex-column align-items-center align-content-center justify-content-center'>
          <TrailTitle open className='pb-2 mb-5 align-center'>
            <div>Indikator Mutu Layanan</div>
            <div>
              Tahun {pageContext.year} Triwulan {pageContext.trimester}
            </div>
          </TrailTitle>
        </Col>
        <Col
          xs={"12"}
          className='d-flex flex-column align-items-center align-content-center pl-3 pr-3 pt-1'>
          <ReactMarkdown
            source={data.quality.description.value}
            escapeHtml={false}
          />
          <Row className='w-100 justify-content-center align-items-center align-content-center mt-3'>
            {data.details.edges.map(({ node: { description, photo } }, idx) => (
              <Col xs={"12"} md={"8"} key={idx} className='mb-2 mt-2'>
                <Img fluid={photo.value.childImageSharp.fluid} />
                <div className='mt-1 text-center'>
                  <ReactMarkdown
                    source={description.value}
                    escapeHtml={false}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </BgRow>
    </>
  );
};

export const query = graphql`
  query($year: Date!, $trimester: String!, $laporan_id: String!) {
    logo: file(name: { eq: "logo-2023" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover: file(name: { eq: "bg-common" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: WEBP) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    quality: cockpitLaporanMutu(
      display: { value: { eq: true } }
      year: { value: { eq: $year } }
      trimester: { value: { eq: $trimester } }
    ) {
      cockpitId
      cockpitCreated
      year {
        value
      }
      trimester {
        value
      }
      description {
        value
      }
    }
    details: allCockpitDetailLaporanMutu(
      filter: { laporan_mutu: { value: { _id: { eq: $laporan_id } } } }
    ) {
      edges {
        node {
          cockpitId
          cockpitCreated
          description {
            value
          }
          photo {
            value {
              childImageSharp {
                fluid(maxWidth: 1920, toFormat: WEBP) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default QualityByTrimesterPage;
